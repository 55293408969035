.datepicker--inline {
    display: flex;
    font-size: inherit;
    cursor: pointer;
    align-items: center;
}

.datepicker-inline:hover {
    color: var(--primary-color);
}

.datepicker--inline .datepicker__icon {
    margin-left: 1rem;
    opacity: 0.5;
    font-size: 1.6em;
    display: inline-block;
    line-height: 1em;
}