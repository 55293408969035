.ql-snow .ql-editor {
    font-family: 'Montserrat';
    font-size: 14px;
}

.ql-snow .ql-editor {
    max-height: 400px;
}

/*.ql-snow .ql-editor > p + p {
    margin-top: 1.6rem;
}

.ql-snow .ql-editor > h1 + *,
.ql-snow .ql-editor > h2 + *,
.ql-snow .ql-editor > h3 + *,
.ql-snow .ql-editor > h4 + *,
.ql-snow .ql-editor > h5 + *,
.ql-snow .ql-editor > h6 + * {
    margin-top: 1em !important;
}*/

.ql-snow .ql-editor > * + * {
  margin-top: 1.2rem;
}
.ql-snow .ql-editor > li + li {
  margin-top: 0.8rem;
}

.ql-snow .ql-editor {
    padding: 1.6rem;
}

.ql-bubble .ql-editor {
    padding: 0;
}

.ql-container.ql-bubble {
    font-family: inherit;
    font-size: inherit;
}