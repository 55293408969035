.section-placeholder--box {
    margin: 3rem 1rem;
    padding: 3rem;
    border: dashed 5px rgba(0,0,0,0.1);
    border-radius: 5px;
    color: rgba(0,0,0,0.1);
    font-size: 3rem;
    text-align: center;
}

.section-placeholder {
    margin: 1rem 1rem;
    padding: 0;
    background-color: var(--success-color);
    height: 10px;
    font-size: 1rem;
    text-align: center;
    color: transparent;
}

[isfocused="true"] {
    text-transform: none !important;
}

.l-section {
    position: relative;
    transition: all .3s;
}

.l-section {
    user-select: none;
}

.l-section.is-over:after {
    content: '';
    display: flex;
    width: 100%;
    height: 40px;
    background-color: var(--primary-color);
    margin: 0;
    position: relative;
    margin-top: 3rem;
}

.l-section.is-over {
    margin-bottom: 3rem !important;
}

.l-section .section-wrapper {
    transition: opacity .3s;
}

.l-section.is-pending .section-wrapper {
    opacity: 0.5;
    filter: grayscale(100%);
    /*pointer-events: none !important;*/
}

.section-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
}

.ql-editor {
    padding: 0;
    text-align: inherit;
    overflow: hidden auto;
}

.dev-tools {
    position: fixed;
    background-color: whitesmoke;
    height: 100vh;
    left: 60px;
    z-index: 2;
    box-shadow: 0 0 40px rgba(0,0,0,0.4);
    display: flex;
    flex-direction: column;
    transition: all .3s;
    font-family: 'Montserrat';
}

.dev-tools.dev-tools--hide {
    opacity: 0;
    pointer-events: none;
    transform: translateX(-4rem);
}

.dev-tools-inner {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.dev-tools .form__tabs {
    flex-direction: column;
}

.dev-tools .form__tabs-nav {
    flex-direction: row;
}

.sidepanel-nav {
    min-height: 48px;
    display: flex;
    flex-direction: row;
}

.sidepanel-content {
    overflow: hidden auto;
    display: flex;
    flex: 1;
    flex-direction: column;
}

.sidepanel-area {
    min-height: 300px;
    flex-grow: 0;
    overflow: hidden auto;
}

.sidepanel-area-header {
    padding: 0.6rem 1rem;
    font-size: 12px;
    background-color: rgba(0,0,0,0.1);
    font-weight: 600;
}

.ide-nav {
    position: fixed;
    width: 60px;
    height: 100vh;
    left: 0;
    z-index: 3;
    background-color: #7c4dff;
}

.ide-side-nav {
    display: flex;
    flex-direction: column;
    color: white;
    justify-content: center;
    align-items: center;
}

.side-nav-icon {
    opacity: 0.5;
}

.side-nav-icon.active {
    opacity: 1;
}

.list-wrapper--sidebar {
    padding: 1rem;
}

.l-section:hover {
    box-shadow: inset 0px 0px 5px #7c4dff;
}

.ide-toolbar {
    z-index: 999;
}

.ide-toolbar__options {
    display: flex;
}

.toolbar-section.toolbar-section,
.toolbar-layout-section.toolbar-layout-section{
    color: white;
    background-color: var(--primary-color);
    padding: 0.4rem 1rem;
}

.toolbar-layout-section.toolbar-layout-section {
    color: white;
    background-color: gray;
}

.toolbar-section > .icon-btn + .icon-btn,
.toolbar-layout-section > .icon-btn + .icon-btn {
    margin-left: 1rem;
}

.toolbar-card {
    padding: 0.4rem;
    transform: translateY(100%);
}

.icon-btn {
    transition: opacity .3s;
    opacity: 0.5;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-btn:hover {
    opacity: 1;
}

.icon-btn.active {
    color: var(--primary-color);
    opacity: 1;
}

.image-placeholder {
    font-family: var(--font-family);
    background-color: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transition: background-color .3s;
    cursor: pointer;
    text-align: center;
}

.image-placeholder:hover {
    background-color: lightgray;
}

.section-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 3px;
    font-size: 14px;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0,0,0,0.05);
    cursor: pointer;
}

.section-info__label {
    padding: 0.8rem;
    padding-left: 0.8rem;
}

.section-info--template.section-info--empty {
    background-color: transparent;
    box-shadow: none;
    color: rgba(0,0,0,0.5);
}

.section-info:hover {
    background-color: lightgray;
}

.section-info.active {
    background-color: var(--primary-color);
    color: white;
}