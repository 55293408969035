.survey-question__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.2rem 1rem;
}

.survey-question__name {
    font-size: 12px;
}

.survey-question:nth-child(even) {
    background-color: rgba(0,0,0,0.03);
}

.survey-question__notes {
    margin-top: 0.6rem;
    margin-bottom: 1.6rem;
}

.survey-question__notes .field__label {
    display: none !important;
}

.survey-subsection .survey-question + .survey-question {
    border-top: solid 1px rgba(0,0,0,0.1);
}

.survey-subsection__header,
.survey-subsection .collection .field__label {
    padding: 0.8rem 1rem;
}

.survey-subsection__name {
    font-size: 16px;
    font-weight: 600;
}

.survey-subsection + .survey-subsection {
    margin-top: 1.4rem;
}

.text-danger {
    color: var(--danger-color);
}

.text-success {
    color: var(--active-color);
}

.audit-item {
    cursor: pointer;
    transition: all .3s;
}

.audit-item:hover {
    background-color: rgba(0,0,0,0.1);
}

@media only screen and (max-width: 800px) {
    .survey-subsection {
        margin-left: -1rem;
        margin-right: -1rem;
    }
}

@media only screen and (min-width: 801px) {
    .audit-survey-dialog {
        display: flex;
        flex: 1;
        flex-direction: column;
    }
}