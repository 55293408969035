.select--inline {
    display: flex;
    align-items: center;
    min-height: 48px;
}

table .select--inline {
    min-height: 0;
}

.list-item .select--inline {
    display: inline-flex;
    min-height: 0;
}

.select.select--input {
    padding: 0 14px;
    min-height: 53px;
    justify-content: space-between;
}

.select__values {
    display: flex;
}

.select__label {
    opacity: 0.4;
    font-size: 12px;
}

.form-layout--cols .select__label {
    display: none;
}

.select--inline.select--inline .select__label {
    margin-bottom: 0;
    margin-right: 1rem;
}

.select__values > * + * {
    margin-left: 0.4rem;
}

.select__value--empty {
    opacity: 0.3;
}

.select--input .select__value--empty {
    display: none;
}

.select__value {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.select__value-text {
    display: flex;
    align-items: center;
}

.select__value-text > * + * {
    margin-left: 0.4rem;
}

.select--input.select--input {
    cursor: pointer;
}

.select--input .select--chip {
    padding: 0.6rem 0.6rem 0.2rem 0.6rem;
}

.select--dropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: solid 1px rgba(0,0,0,0.1);
    border-radius: 4px;
    padding: 4px 4px 4px 12px;
    transition: all .3s;
}

.select--dropdown:hover {
    background-color: rgba(0,0,0,0.02);
    border: solid 1px rgba(0,0,0,0.3);
}

.select--dropdown .select__value {
    font-size: 12px;
}

.select--dropdown .select__label + .select__values_outer {

}

.select--dropdown-lg .select__value {
    font-size: 14px;
    font-weight: 600;
}

.select--dropdown .select__value {
}

.select--dropdown-box .select__values {
    background-color: rgba(0,0,0,0.05);
    border-radius: 4px;
    padding: 4px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    min-width: 200px;
}

.select--dropdown-box .select__values:hover {
    background-color: rgba(0,0,0,0.1);
}

.select--chip .select__label,
.select--radio-chip .select__label {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0.6rem;
    opacity: 1;
}

.select--chip .select__values,
.select--radio-chip .select__values {
    flex-wrap: wrap;
    margin-top: -2px;
    margin-left: -2px;
}

.select__values_outer {
    display: flex;
    align-items: center;
}

.select--empty {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.select--empty .select__label {
    margin-bottom: 0;
}

.chip {
    background-color: rgba(0,0,0,0.1);
    border-radius: 18px;
    min-height: 32px;
    margin: 2px;
    cursor: pointer;
}

.chip:hover {
    background-color: rgba(0,0,0,0.15);
}

.chip .select__value-text {
    padding: 0 14px;
    font-size: 12px;
}

.chip .clear-icon {
    background-color: rgba(0,0,0,0.6);
    color: white;
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    font-size:16px;
    padding: 2px;
}

.chip .check-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-left: 8px;
}

.chip.selected {
    background-color: rgba(var(--primary-color-rgb), 0.2);
    color: var(--primary-color);
}

.chip.outlined {
    background-color: transparent;
    border: solid 1px rgba(0,0,0,0.2);
}

.chip.outlined.selected {
    border: solid 1px var(--primary-color);
    background-color: rgba(var(--primary-color-rgb), 0.1);
    color: var(--primary-color);
}

.select-list__value {
    display: flex;
    align-items: center;
}

.select-list__value > * + * {
    margin-left: 0.4rem;
}

.info-field .select--inline {
    min-height: 0;
}

table .select__label {
    display: none;
}