.graph-canvas {
    transition: all .3s;
    height:100%;
}

.list-wrapper--graph .resource-list,
.list-wrapper--graph .list-scroll {
    overflow: hidden;
}

.graph-item {
    cursor: pointer;
}

.graph-item:hover {
    background-color: rgba(0,0,0,0.05);
}

.graph-item.is-selected {
    background-color: var(--primary-color);
    color: white;
}

.graph-item.is-parent {
    opacity: 0.5 !important;
}