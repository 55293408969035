.se-root {
    overflow: hidden auto;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.se-header {
    background-color: white;
    padding: 0 1rem;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
}

.se-header-icons {
    display: flex;
    align-items: center;
}

.se-header-icons > * + * {
    margin-left: 1rem;
}

.se-path-outer {
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
}

.se-path {
    display: flex;
    font-family: 'Montserrat';
    padding: 1rem 1rem;
    font-size: 0.8rem;
}

.se-path > * + * {
    margin-left: 1rem;
}

.se-path-item {
    opacity: 0.3;
    cursor: pointer;
    transition: opacity .3s;
}

.se-path-item:hover {
    opacity: 1;
}

.se-path-item.active {
    opacity: 1;
}

.se-element {
    font-family: var(--font-family);
    max-width: 100%;
    flex: 1;
    border-top: solid 1px rgba(0,0,0,0.05);
    transition: all .3s;
}

.se-element:first-child {
    border-top: 0;
}

.se-element.is-open {
    background-color: white;
    margin: 1rem 0;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.se-element.is-open + .se-element {
    border-top: 0;
}

.se-element__heading {
    padding: 0;
    margin: 0 1rem;
    font-size: 12px;
    cursor: pointer;
    min-height: 40px;
    display: flex;
    align-items: center;
}

.se-prop:last-child {
    padding-bottom: 1rem;
}

.se-element__label {
    font-size: 12px;
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
}

.se-element__label + .se-element__value {
    margin-top: 1rem;
}

.se-element-actions {
    display: flex;
    width: auto;
    height: auto;
    flex: 1;
    justify-content: flex-end;
}

.se-icon-button {
    padding: 0.2rem 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.se-icon-button.success {
    color: white;
    background-color: var(--success-color);
}

.se-icon-button.primary {
    color: var(--primary-color);
}

.se-icon-button.inactive {
    color: inherit;
    opacity: 0.2;
}

.se-color {
    width: 24px;
    height: 24px;
    transition: all .3s;
    cursor: pointer;
    border-radius: 99px;
    margin: 1px;
    border: solid 1px rgba(0,0,0,0.2);
}

.se-color.active, .se-color:hover {
    box-shadow: 0 5px 10px rgba(0,0,0,0.2);
}

.se-classes {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
}

.se-classes + input {
    padding: 0.6rem 0;
}

.se-class {
    background-color: rgba(0,0,0,0.05);
    border-radius: 2px;
    font-size: 12px;
    padding: 3px 6px;
    margin-bottom: 5px;
    margin-right: 5px;
    transition: all .3s;
}

.se-class:first-child:last-child {
    margin-right: 0;
}

.se-class--select {
    min-width: 160px;
    padding: 6px 10px;
    text-align: center;
    flex: 1;
    cursor: pointer;
}

.se-class--select.se-class--empty {
    background-color: transparent;
}

.se-class--icon {
    display: flex;
    font-size: 18px;
    text-align: center;
    align-items: center;
    cursor: pointer;
}

.se-class.active {
    background-color: var(--primary-color);
    color: white;
}

.se-class:hover {
    background-color: rgba(0,0,0,0.1);
    color: var(--primary-color);
}

.se-element__groups {
    margin-top: 0.4rem;
}

.se-prop {
    display: flex;
    max-width: 100%;
    padding: 0.4rem 1rem;
}

.se-prop + .se-prop {
    padding-top: 0;
}

.se-prop + .se-prop--empty {
    padding-top: 0.8rem;
}

.se-prop--empty + .se-prop--empty {
    padding-top: 0;
}

.se-prop--empty {
    background: rgba(0,0,0,0.05);
}

.se-element__groups > .se-prop + .se-prop {
    /*padding-top: 0.8rem;
    border-top: solid 1px rgba(0,0,0,0.05);*/
}

.se-prop__label {
    width: 120px;
    white-space: nowrap;
    font-size: 12px;
    opacity: 0.8;
    padding-right: 0.8rem;
    flex-shrink: 0;
    line-height: 1.2rem;
}

.se-prop:not(.se-prop--empty) + .se-prop--empty {
    margin-top: 0.4rem;
}

.se-prop--empty .se-prop__label {
    opacity: 0.5;
}

.se-prop__value {
    flex: 1;
    display: flex;
    justify-content: space-between;
}

.se-prop-reset {
    margin-left: 1rem;
}

.se-radio {
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    background-color: white;
    font-size: 12px;
    border-radius: 2px;
}

.se-radio__opt {
    opacity: 0.5;
    padding: 3px 8px;
    text-align: center;
    cursor: pointer;
    transition: all .3s;
}

.se-radio__opt:hover {
    background-color: rgba(0,0,0,0.1);
    color: var(--primary-color);
}

.se-radio__opt.active {
    opacity: 1;
    background-color: var(--primary-color);
    color: white;
}

.se-inline-input input {
    text-align: right;
}

.se-undefined-elements {
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 4px;
}

.se-define-element {
    color: rgba(0,0,0,0.3);
    padding: 0.6rem 0.8rem;
    background-color: rgba(0,0,0,0.03);
    border-radius: 4px;
    font-size: 12px;
    cursor: cell;
    transition: all .3s;
}

.se-define-element:hover {
    background-color: white;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.se-style-select {
    cursor: pointer;
    transition: all .3s;
}

.se-style-select:hover {
    opacity: 0.6;
}

.se-docs-option {
    padding: 8px 0;
    transition: background-color .3s;
    cursor: pointer;
    border-bottom: solid 1px rgba(0,0,0,0.05);
}

.se-docs-option.active {
    background-color: rgba(0,0,0,0.05);
}

.se-docs-option:hover {
    background-color: rgba(0,0,0,0.05);
}

.se-docs-option__check {
    width: 32px;
}

.se-docs-option__name {
    font-size: 12px;
    font-weight: 600;
}

.se-docs-option__name .se-class {
    margin-right: 1em;
    font-weight: 400;
}

.se-docs-option__desc,
.se-docs-prop__desc {
    padding-top: 8px;
    font-size: 10px;
    opacity: 0.6;
}