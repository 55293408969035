.section-preview-outer {
    padding: 5px;
}

.section-preview {
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
    width: 360px;
    height: 260px !important;
    overflow: hidden;
    position: relative;
    border-radius: 4px;
    background-color: white;
}

.section-preview__header {
    position: absolute;
    bottom: 5px;
    left: 5px;
    right: auto;
    border-radius: 3px;
    padding: 0.4rem 0.6rem;
    font-size: 0.8rem;
    background-color: rgba(0,0,0,0.75);
    color: white;
}

.section-preview .section-canvas {
    pointer-events: none;
    transform: scale(0.3);
    width: 1270px;
    height: 940px;
    transform-origin: left top 0px;
    overflow: hidden;
    background-color: white;
}

.section-preview__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.4);
    transition: opacity .3s;
    opacity: 0;
}

.section-preview__loader {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section-preview:hover .section-preview__overlay {
    opacity: 1;
}

.section-preview section.l-fixed-top {
    position: absolute;
    left: 0;
}

.section-preview section.l-fixed-top.t-on-image {
    background: black;
}