.info-field {
    padding-bottom: 0.6rem;
    border-bottom: solid 1px rgba(0,0,0,0.1);
}

.info-field__label {
    font-size: 12px;
    opacity: 0.5;
    margin-bottom: 0.4rem;
}

.info-field__value {
    font-size: 13px;
    display: flex;
    justify-content: space-between;
}

.info-field--address {
    padding-bottom: 0;
}

.info-field--address .info-field__label {
    margin-bottom: 0;
}

.info-field--rich p:first-child {
    margin-top: 0;
}

.info-field--ref {
    border-bottom: none;
}