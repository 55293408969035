.gallery-card__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.gallery-card__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    font-size: 14px;
    padding-top: 1rem;
    background: linear-gradient(0deg,rgba(0,0,0,0.75) 0,rgba(0,0,0,0) 100%);
}
.resource-grid.cms-entries-gallery {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
}