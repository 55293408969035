.side-sheet {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1100;
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 800px;
    max-width: 80vw;
    box-shadow: -10px 0 20px rgba(0,0,0,0.1);
}

.side-sheet--lg {
    width: 1100px;
}

.side-sheet--sm {
    width: 780px;
}