.task-outer {
    position: relative;
}

.task-item {
    padding: 0 0 0 1rem;
    font-size: 12px;
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.task-info {
    padding-left: 64px;
    font-size: 11px;
    padding-bottom: 1rem;
    padding-right: 1rem;
}

.task-item + .task-item,
.task-outer + .task-outer {
    border-top: solid 1px rgba(0,0,0,0.1);
}

.task-item:hover {
    background-color: rgba(0,0,0,0.05);
}

.task-item textarea {
    font-size: inherit;
    font-family: inherit;
    background-color: transparent;
    border: none;
    overflow: hidden;
    resize: none;
    border-radius: 0;
    width: 100%;
}

.task-item textarea:focus {
    outline: none;
}

.task-item .select__value-desc {
    display: none;
}

.task-item__title {
    flex: 1;
    display: flex;
    align-items: center;
}

.task-item__title > * + * {
    margin-left: 1rem;
}

.task-item .input-checker {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    font-size: 20px;
}

.task-color {
    width: 5px;
    height: auto;
    top: 0;
    bottom: 0;
    position: absolute;
}

.task-props {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.task-props > * + * {
    margin-left: 1rem;
}

@media only screen and (max-width: 800px) {
    .task-props {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0.4rem;
    }
    .task-props > * + * {
        margin: 0;
    }
}

.task-field__value {
    border: solid 1px rgba(0,0,0,0.1);
    border-radius: 4px;
    padding: 4px 8px;
    min-height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    min-width: 100px;
}

.task-field__value.is-empty {
    color: rgba(0,0,0,0.4);
}

.task-field__value.active {
    color: white;
    background-color: var(--primary-color);
}

.task-field__label {
    font-size: 12px;
    opacity: 0.5;
    margin-bottom: 0.4rem;
    display: none;
}

.task-assign {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
}

.task-assign__avatar {
    border-radius: 999px;
    width: 24px;
    height: 24px;
    background-color: rgba(0,0,0,0.1);
}

.task-assign__value .select__value-desc {
    margin-left: 0.6rem;
}

.task-assign__value .select__value-desc {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.task-date {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
}

.task-date__icon {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: rgba(0,0,0,0.4);
}

.task-date__value {
    margin-left: 0.6rem;
}

.task-description {
    font-size: 1.2rem;
    margin-bottom: 1.2rem;
}

.task-description.is-empty {
    color: rgba(0,0,0,0.2);
}

.task-history-entry {
    font-size: 12px;
    color: rgba(0,0,0,0.4);
    display: flex;
    align-items: center;
}

.task-history-entry > * + * {
    margin-left: 0.4em;
}

.task-history-entry__created_by {
    margin-left: 1em;
}

@media only screen and (min-width: 801px) {
    .task-description {
        font-size: 1.4rem;
    }
    .task-field__value {
        font-size: 12px;
        padding: 4px 12px;
        min-height: 36px;
    }
    .task-field:hover .task-field__value {
        border-color: var(--primary-color);
        background-color: rgba(0,0,0,0.05);
    }
}