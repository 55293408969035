.mobile-filters {
    background-color: white;
    box-shadow: 0 1px 5px rgba(0,0,0,0.1);
    z-index: 1;
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}

.mobile-filters .list-filters {
    flex: 1;
}