.nav-pills {
    display: flex;
    font-family: var(--font-family);
    flex-shrink: 0;
    align-items: center;
}

.color-dot {
    width: 12px;
    height: 12px;
    border-radius: 16px;
}

.color-dot + * {
    margin-left: 0.4rem;
}

.nav-pill {
    padding: 0.4rem 1rem;
    border-radius: 30px;
    cursor: pointer;
    height: 100%;
    transition: background-color .3s;
    font-size: 0.9rem;
    color: rgba(0,0,0,0.7);
    letter-spacing: 0.4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-pill.active {
    background-color: rgba(0,0,0,0.1);
    color: var(--primary-color);
}

.nav-pill:hover {
    background-color: rgba(0,0,0,0.05);
}

.nav-pill--selectable {
    border: solid 1px rgba(0,0,0,0.1);
}

.nav-pill__icon {
    font-size: 18px;
    display: flex;
}

.nav-pill--selectable .nav-pill__label {
    opacity: 0.5;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1rem;
    display: flex;
}

.nav-pill__label {
    display: flex;
    flex: 1;
    align-items: center;
}

.nav-pills--vertical {
    flex-direction: column;
    flex: 1;
    align-items: stretch;
    padding: 0;
}

.nav-pills--vertical .nav-pill {
    flex: 1;
}

.nav-pills--vertical > * + * {
    margin-top: 1px;
}

.card-icon {
    text-align: left;
    transition: background-color .3s;
    padding: 0.4rem 0.8rem;
    font-family: var(--font-family);
    cursor: pointer;
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 12px;
}

.card-icon:hover {
    background-color: rgba(0,0,0,0.01);
}

.card-icon__icon {
    font-size: 2rem;
    margin-right: 0.8rem;
}

.card-icon__avatar {
    background-color: var(--primary-color) !important;
    color: white !important;
}