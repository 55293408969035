.props-table td + td {
    padding-left: 1rem;
}

.props-table td {
    padding-top: 4px;
    padding-bottom: 4px;
}

.props-table td:first-child {
    font-weight: 600;
}

.info-field {
    display: flex;
    flex-direction: column;
}

.field-layout--props > .fields-list {
    display: flex;
}

.field-layout--props .info-field {
    padding-bottom: 0;
}

.field-layout--props > .fields-list > div { display: flex; }

.field-layout--props > .fields-list > div > div {
    display: flex;
    padding-top: 4px;
    padding-bottom: 4px;
    vertical-align: middle;
}

.field-layout--props > .fields-list > div > div + div { padding-left: 1rem; }

.field-layout--props-list .fields-list > * + * {
    margin-top: 0;
}

.field-layout--props-list .info-field__value {
    flex: 1;
    align-items: center;
}