.list-wrapper {
    transition: transform .3s;
}

.form__header--mobile {
    display: none;
}

@media only screen and (min-width: 801px) {
    .form {
        min-width: 680px;
    }
    .hide-desktop {
        display: none !important;
    }
}

@media only screen and (max-width: 1400px) {
    .grid-cards {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 1100px) {
    .grid-cards {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 800px) {
    .hide-phone {
        display: none !important;
    }
    .app {
        padding: 56px 0 0 0;
        overflow: hidden auto;
        max-height: none;
    }
    .app-menu {
        display: block;
    }
    .apps-nav {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
    }
    .apps-nav-layout {
        flex-direction: column-reverse;
        overflow: visible;
        padding-top: 4rem;
    }
    .list-area {
        display: flex;
        width: 100%;
    }
    .list-wrapper {
        /*display: block;*/
        flex: 1;
    }
    .list-heading {
        padding: 1rem 1rem 0.4rem 1rem;
    }
    .resource-list {
        max-width: 100vw !important;
        width: 100vw !important;
        flex-direction: column;
    }
    .list-aside {
        flex-direction: row;
        border-left: none;
        border-top: solid 1px rgba(0,0,0,0.05);
    }
    .resource-list-fab {
        position: fixed;
    }
    .resource-list--primary {
        border-radius: 0;
        box-shadow: none;
        padding-top: 1rem;
    }
    .list-area.secondary .list-wrapper--primary {
        transform: translateX(-100%);
        display: none;
    }
    .list-area.secondary .list-wrapper--secondary {

    }
    .app-columns {

    }
    .app-menu {
        flex-direction: column;
        width: 100%;
    }
    .app-menu > * + * {
        margin-top: 1rem;
    }

    .form__tabs {
        flex-direction: column;
    }

    .form__tabs-nav {
        flex-direction: row;
        border-right: 0;
        border-top: solid 1px rgba(0,0,0,0.1);
        padding: 0;
        justify-content: center;
        box-shadow: 0 -5px 10px rgba(0,0,0,0.1);
        z-index: 2;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 56px;
        background-color: white;
    }

    .form__tabs-nav > * + * {
        margin: 1rem;
    }

    .form__body {
        padding: 1rem 1rem 6rem 1rem;
        overflow: visible;
    }

    .apps-nav {
        flex-basis: 0;
    }

    .apps-nav-layout {
    }

    .app-nav-resources {
        grid-template-columns: repeat(2, 1fr);
    }

    .form__footer {
        display: none;
    }

    .form__footer--dialog {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
    }

    .form__body .resource-list {
        max-width: 100% !important;
        width: 100% !important;
    }

    .form {
        max-width: 100vw !important;
    }

    .dev-tools {
        display: none !important;
    }

    .side-filter-layout {
        display: flex;
        flex-direction: column;
        flex:1;
    }

    .grid-cards {
        grid-template-columns: repeat(1, 1fr);
    }

    .form__header {
        padding: 0;
        background-color: white;
        border-bottom: none;
        box-shadow: 0 5px 10px rgba(0,0,0,0.1);
        min-height: 56px;
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 2;
    }

    .form__title {
        flex: 1;
        text-align: center;
    }

    .form__tabs {
        flex-direction: column-reverse;
    }

    .form__header--desktop {
        display: none;
    }

    .form__header--mobile {
        padding: 0 0.4rem;
        display: flex;
        box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
        background-color: var(--success-color);
        color: white;
        z-index: 1101;
    }

    .list-aside {
        padding: 0;
    }

    .resource-grid .media-preview {
        height: 60px !important;
    }

    input[type="text"] {
        font-size: 16px !important;
    }
}

.dev-tools .list-aside {
    padding: 0;
}

.dev-tools .resource-grid {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(auto-fill, 60px);
}

.dev-tools .resource-grid .media-preview {
    height: 60px !important;
}

@media only screen and (max-width: 1500px) {
    .app-columns .form {
        position: fixed;
        top: 1rem;
        bottom: 1rem;
        right: 1rem;
        max-width: 80vw;
        width: 800px;
        z-index: 1100;
    }
    .form-backdrop.visible {
        opacity: 1;
        pointer-events:all;
    }
}

@media only screen and (min-width: 1501px) {
    .app-columns {
        --list-width-filter-aside: 960px;
    }
}

@media only screen and (max-width: 1200px) {
    .app-columns .form {
        top: 0;
        bottom: 0;
        right: 0;
        border-radius: 0;
    }
}

@media only screen and (max-width: 800px) {
    .form {
        max-width: none;
        width: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 0;
    }
    .modules-sidebar {
        display: none;
    }
    .list-wrapper.filter-layout--aside {
        flex-direction: column;
    }
    .list-filter-area.list-filter-area {
        position: fixed;
        left: 0;
        right: auto;
        top: 40px;
        bottom: 0;
        z-index: 1000;
        background-color: white;
        padding: 1rem;
        overflow: hidden auto;
        transition: transform .3s, box-shadow .3s;
        transform: translateX(-100%);
        width: auto;
        flex-basis: auto;

    }
    .list-filter-area.open {
        transform: translateX(0);
        box-shadow: 0 0 10px 5px rgba(0,0,0,0.1);
    }

    .filter-area-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.8);
        z-index: 900;
        transition: opacity .3s;
    }

    .filter-area-backdrop.open {
        opacity: 1;
        pointer-events: all;
    }

    .list-item > div[role="button"] {
        min-height: 64px;
    }
}

@media only screen and (max-width: 800px) {
    .form-field--labeled .form-field__layout {
        /*flex-direction: column;*/
        align-items: center;
    }
    .field-layout--cols .fields-list {
        flex-direction: column;
    }
    .field-layout--cols > .fields-list > * + * {
        margin-left: 0;
        margin-top: 0.4rem;
    }
    .snackbar .success {
        display: none !important;
    }
}