.note {
    padding: 1rem;
    /*box-shadow: 0 0 20px rgba(0,0,0,0.05);*/
    background-color: rgba(0,0,0,0.03);
    border-radius: 3px;
}

.author-info {
    font-family: var(--font-family);
    font-size: 12px;
    color: rgba(0,0,0,0.5);
    display: flex;
}

.author-info > * + * {
    margin-left: 0.6rem;
}

.note-content {
    font-size: 14px;
}

.note-content:focus {
    outline: none;
}

.note .field__label {
    display: none !important;
}

.note .collection {
    margin-bottom: 1rem;
}

.note .resource-list {
    overflow: visible;
}

.note .list-scroll {
    overflow: visible;
}

.note .media-grid {
    grid-template-columns: repeat(4, minmax(80px, 1fr));
    grid-gap: 5px;
}

.note__footer {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

@media only screen and (max-width: 800px) {
    .note {
        padding: 0.6rem;
    }
    .note .media-grid {
        grid-template-columns: repeat(4, minmax(60px, 1fr));
        padding: 0;
    }
}