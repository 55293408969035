.gallery {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.9);
    z-index: 10000;
    pointer-events: none;
    opacity: 0;
    transition: all .3s;
    color: white;
    display: flex;
    flex-direction: column;
}

.gallery--open {
    opacity: 1;
    pointer-events: all;
}

.gallery__toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.gallery__info {
    padding: 0 1rem;
    display: flex;
    align-items: center;
}

.gallery__preview {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.gallery__preview img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}