:root {
    --sidebar-nav-width: 60px;
    --sidebar-width: calc(var(--sidebar-content-width) + var(--sidebar-nav-width));
    --sidebar-content-width: 400px;
}

.ide-wrapper {
    width: 100%;
    position: relative;
    transition: transform .3s, max-width .3s;
    transform-origin: top left;
}

.with-sidebar .ide-wrapper {
    transform-origin: top center;
}

.ide-wrapper * {
    transition: background-color .3s, opacity .3s;
}

.dev-tools {
    width: var(--sidebar-content-width);
}

.sidebar-lg {
    --sidebar-content-width: 600px;
    --sidebar-width: calc(var(--sidebar-content-width) + var(--sidebar-nav-width));
}

.zoomed-out .ide-wrapper {
    max-width: 1360px;
    transform: translateX(60px) scale(0.4);
}

.l-fixed-top.l-fixed-top {
    right: 0;
    width: auto;
}

.zoomed-in.with-sidebar .l-fixed-left.l-fixed-left {
    left: var(--sidebar-width);
}

.zoomed-in.with-sidebar .l-fixed-overlay.l-fixed-overlay {
    left: var(--sidebar-width);
}

.zoomed-in.with-sidebar .l-fixed-bottom.l-fixed-bottom {
    left: var(--sidebar-width);
}

.with-sidebar  .l-fixed-top.l-fixed-top {
    left: var(--sidebar-width);
    right: 0;
    width: auto;
}

.with-sidebar .theme-preloader {
    left: var(--sidebar-width);
    right: 0;
    width: auto;
}

.zoomed-out.with-sidebar .ide-wrapper {
    transform: scale(0.4) translateX(130px);
}

.zoomed-out.with-sidebar.sidebar-lg .ide-wrapper {
    transform: translateX(250px) scale(0.4);
}

.with-sidebar .ide-wrapper {
    padding-left: var(--sidebar-width) !important;
}

.zoomed-out .ide-wrapper {
    padding-left: 0 !important;
}

.sections .ide-page {
    position: absolute;
    left: 0;
    right: 0;
}

.sections .attach-section-dialog {
    right: 0;
    position: fixed;
    left: calc((1360px * 0.4) + 60px);
    height: 100vh;
}

.sections .attach-section-dialog .form__footer {
    display: none;
}

#wrapper.device-phone {
    max-width: 375px;
    margin: 0 auto;
}

#wrapper.device-phone .l-fixed-top {
    width: 375px;
    margin: 0 auto;
}

@media only screen and (max-width: 1360px) {
    .sections .attach-section-dialog {
        left: calc((100vw * 0.4) + 60px);
    }
}

.undocked-sidebar .ide-nav,
.undocked-sidebar .dev-tools {
    z-index: 1200;
}

.undocked-sidebar .ide-wrapper {
    padding-left: var(--sidebar-nav-width) !important;
}

.hide-overlays .l-fixed-top,
.hide-overlays .l-fixed-bottom,
.hide-overlays .l-fixed-left,
.hide-overlays .l-fixed-right,
.hide-overlays .l-fixed-right-bottom,
.hide-overlays .l-fixed-right-top,
.hide-overlays .l-fixed-left-bottom,
.hide-overlays .l-fixed-left-top,
.hide-overlays .l-fixed-overlay {
    display: none !important;
}

.ide-aside:hover .dev-tools {
}

.undocked-sidebar .ide-aside:hover .dev-tools {
    display: flex;
}

.undocked-sidebar .dev-tools {
    display: none;
}