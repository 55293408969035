.notification {
    font-family: var(--font-family);
    padding: 1rem;
    min-width: 340px;
}

.notification + .notification {
    border-top: solid 1px rgba(0,0,0,0.1);
}

.notification__info {
    display: flex;
    align-items: center;
}

.notification__status-icon {
    margin-right: 0.8rem;
    display: flex;
    align-items: center;
}

.notification__title {
    font-size: 1rem;
}

.notification__subtitle {
    opacity: 0.5;
    font-size: 0.8rem;
    padding-left: 36px;
}

.notification__log {
    opacity: 0.5;
    font-size: 0.8rem;
    padding-left: 36px;
    padding-top: 0.8rem;
}

.notification__log_entry {
    display: flex;
}