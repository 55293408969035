.ui-preloader {
    background-color: rgba(255,255,255,0.6);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    transition: opacity .1s;
    pointer-events: none;
    opacity: 0;
    z-index: 1;
}

.ui-preloader.is-visible {
    opacity: 1;
}