.form {
    flex: 1;
    display: flex;
    flex-direction: column;

    background: white;
    box-shadow: 0 0 40px rgba(0,0,0,0.2);
    border-radius: 5px;
    position: relative;
    opacity: 0;
    transform: translateX(1rem);
    /*transition: transform .3s, opacity .3s;*/
    pointer-events: none;
    max-width: 900px;
}

.form-embed {
    max-width: none;
    box-shadow: none;
}

.form.visible {
    opacity: 1;
    transform: translateX(0);
    pointer-events: all;
}

.form-backdrop {
    position: absolute;
    background: rgba(0,0,0,0.5);
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    pointer-events: none;
    transition: opacity .1s;
    opacity: 0;
    z-index: 2;
}

.form__body {
    padding: 1.4rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden auto;
    flex: 1;
}

.form__tabs {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.form__tabs-nav {
    border-right: solid 1px rgba(0,0,0,0.1);
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0.6rem;
}

.form__sidebar {
    flex-basis: 380px;
    overflow: hidden;
    background-color: rgba(0,0,0,0.05);
    box-shadow: inset 0 0 10px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
}

.form:hover .form__body {
    overflow: hidden auto;
}

.form__header {
    padding: 0.6rem 1.4rem;
    border-bottom: solid 2px rgba(0,0,0,0.05);
    background: rgba(0,0,0,0.025);
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.form__header-fields {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
}

.form__header-fields > h6 {
    flex-grow: 1;
}

.form__header-fields.form__header-fields > * + * {
    margin-left: 0.6rem;
}

.inline-select {
    background-color: rgba(0,0,0,0.1);
    border-radius: 99px;
    padding: 4px 10px;
    font-family: var(--font-family);
    font-size: 12px;
    transition: all .3s;
    cursor: pointer;
    display: flex;
    align-items: center;
    min-width: 120px;
    max-width: 260px;
    justify-content: space-between;
}

.inline-select__icon {
    font-size: 18px;
    display: flex;
    align-items: center;
}

.inline-select:hover {
    background-color: rgba(0,0,0,0.2);
}

.form__title {
    flex: 1;
}

.form__footer {
    padding: 1rem;
    border-top: solid 2px rgba(0,0,0,0.05);
    background: rgba(0,0,0,0.025);
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
}

.inline-field {
}

.inline-field-icon {
    font-size: 1rem;
}

.inline-field-icon + .inline-field {
    margin-left: 0.6rem;
}

.inline-field-base {
    font-family: var(--font-family);
    /*border: solid 1px transparent;
    border-radius: 4px;
    padding: 0.4rem;*/
    cursor: pointer;
    display: flex;
    flex-direction: row;
}

.inline-field-base:hover {
    border-color: rgba(0,0,0,0.1);
}

.inline-field-label {
    margin-bottom: 0.4rem;
    color: rgba(0,0,0,0.5);
    font-size: 12px;
    text-transform: uppercase;
}

.form-field + .form-field {
    margin-top: 1.4rem;
}

@media only screen and (min-width: 801px) {
    .form-layout {
        flex: 1;
    }
    .form-field:first-child .form-layout {
        margin-top: 0;
    }
    .form-layout + .form-layout {
        margin-top: 0;
    }
    .form-layout--cols, .form-layout--cols-flex, .form-layout--cols-even {
        display: flex;
    }
    .form-layout--cols > .form-field + .form-field,
    .form-layout--cols-even > .form-field + .form-field,
    .form-layout--cols-flex > .form-field + .form-field {
        margin-top: 0;
        margin-left: 1rem;
    }
}

@media only screen and (max-width: 800px) {
    .form-field--text .form-field__label {
        display: none;
    }
}

.form-layout--cols-flex {
    justify-content: space-between;
}

.form-layout--cols-even > .form-field {
    flex: 1;
}

.form__header .inline-field {
    flex-direction: row;
    display: flex;
    align-items: center;
}

.form__header .inline-field-label {
    margin-bottom: 0;
    margin-right: 0.4rem;
}

.input-checker {
    border: solid 1px;
    border-radius: 9999px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-checker:not(.checked) {
    color: rgba(0,0,0,0.5) !important;
    opacity: 0.4;
}

.input-checker:hover {
    border-color: var(--active-color);
    color: var(--active-color);
}

.input-checker.checked {
    color: white;
    border-color: var(--active-color);
    background-color: var(--active-color);
}

.input-checker--danger.checked {
    color: white;
    border-color: var(--danger-color);
    background-color: var(--danger-color);
}

.ref-field-empty {
     font-family: var(--font-family);
     background-color: rgba(0,0,0,0.03);
     color: gray;
     border-radius: 4px;
     cursor: pointer;
     font-size: 14px;
     transition: all .3s;
     display: flex;
     align-items: center;
 }

.ref-field-empty:hover {
    background-color: rgba(0,0,0,0.1);
}

.list-collection .resource-grid {
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 10px;
    padding: 10px;
}

.file-preview {
    border-radius: 4px;
}

.media-preview {
    height:80px !important;
}

.chip-input {
    font-family: var(--font-family);
}

.chip-input--horizontal {
    display: flex;
    flex-wrap: wrap;
    margin-top: -4px;
    margin-left: -4px;
}

.chip-input--horizontal > * {
    margin: 4px;
}

.form-field .list-wrapper {
    max-width: none;
}

.form-field + .form-field--tab {
    margin-top: 3rem;
}

.form-collection__actions {
    background-color: rgba(0,0,0,0.05);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.form-collection__actions > div {
    display: flex;
    flex-direction: row;
}

.form-field--labeled .form-field__label {
    display:none;
}

.field-layout .fields-list {
    display: flex;
}

.field-layout--props .fields-list {
    flex-direction: column;
}

.fields-list > * {
    flex: 1;
}

.fields-list > * + * {
    margin-top: 1rem;
}

.field-layout--cols > .fields-list > * + * {
    margin-top: 0;
    margin-left: 1rem;
}

.is-over:before {
    border-radius: 4px;
    box-shadow: inset 0 0 0 2px var(--success-color);
    pointer-events: none;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index:100;
}

.field-add {
    display: flex;
    z-index: 1000;
}

.editable-field.tab .field-add,
.editable-field.layout .field-add {
    background-color: rgba(0,0,0,0.05);
    display: flex;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.editable-field.field .field-add {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    background-color: rgba(255,255,255,0.4);
    pointer-events: none;
    transform: scale(0);
    transition: all .3s;
}

.editable-field.field .field-add > div {
    background: var(--primary-color);
    color: white;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    padding: 0 1rem;
}

.editable-field {
    position: relative;
}

.editable-field:before {
    border-radius: 4px;
    box-shadow: inset 0 0 0 2px var(--primary-color);
    pointer-events: none;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index:100;
    opacity: 0;
    transition: all .3s;
}

.editable-field.is-over:before {
    opacity: 1;
    box-shadow: inset 0 0 0 2px var(--success-color);
}

.editable-field:hover:before {
    opacity: 1;
}

.editable-field:hover > div > div > .field-add {
    display: flex;
    transform: scale(1);
    pointer-events: all;
}

.schema-field {
    font-size: 12px;
    padding: 8px;
    display: flex;
    cursor: pointer;
    transition: all .3s;
    margin-bottom: 4px;
}

.schema-field:hover {
    background-color: rgba(0,0,0,0.05);
}

.schema-field > * + * {
    margin-left: 0.8em;
}

.form__footer--dialog {
    display: flex !important;
}

.info-field {
    display: flex;
    justify-content: space-between;
}

.info-field .field__label {
    display: none !important;
}

.phone-view-select {
    padding: 0 0.4rem;
    border-bottom: solid 1px rgba(0,0,0,0.1);
}

.form-tabs-wrapper {
    display: flex;
    flex: 1;
}

.form-tabs-wrapper .form__body {
    flex: 1;
}

@media only screen and (min-width: 801px) {
    .form-tabs-wrapper {
        overflow: hidden;
    }
    .form-tabs-wrapper .form__body {
        overflow: hidden auto;
    }
}