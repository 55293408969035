.media-library {
    font-family: var(--font-family);
    min-height: 80vh;
    height: 100%;
    max-height: 100%;
}

.media-library__sidebar {
    width: 280px;
    background-color: rgba(0,0,0,0.05);
    overflow: hidden auto;
    flex-shrink: 0;
}

.media-lib {
    padding: 1rem 1rem;
    font-size: 1rem;
}

.media-dir {
    font-size: 0.8rem;
    padding: 0.3rem 1rem 0.3rem 3rem;
}

.media-dir.active,
.media-lib.active {
    background-color: rgba(0,0,0,0.05);
    color: var(--primary-color);
}

.media-dir.has-children {
    padding-left: 0.9rem;
}

.media-lib, .media-dir {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.media-lib > * + *,
.media-dir > * + * {
    margin-left: 0.6rem;
}

.media-lib:hover, .media-dir:hover {
    background-color: rgba(0,0,0,0.05);
}

.media-selection {
    padding: 1rem;
    box-shadow: 0 -3px 10px 0 rgba(0,0,0,0.2);
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.media-selection > * + * {
    margin-left: 2px;
}