:root {
  --list-width-sm: 420px;
  --list-width-lg: 520px;
  --list-width-filter-aside: 100%;
  --list-filter-width: 200px;
  --primary-color: #7c4dff;
  --primary-color-rgb: 	124, 77, 255;
  --success-color: rgb(245, 0, 87);
  --scrollbar-width: 4px;
  --main-columns-gap: 1rem;
  --font-family: 'Montserrat';
  --filter-width: 260px;
  --active-color: #4caf50;
  --danger-color: #f44336;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  padding: var(--scrollbar-width);
}
::-webkit-scrollbar {
  width: var(--scrollbar-width);
  height: var(--scrollbar-width);
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0.2);
  border-radius: 6px;
  cursor: pointer;
  transition: background-color .3s;
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary-color);
}

* {
  /*overscroll-behavior: contain;*/
}

div.no-focus:focus {
  outline:none;
  border:none;
  box-shadow: none;
}

.auth0-lock.auth0-lock .auth0-lock-header-logo {
  width: auto;
  max-width: 100%;
  height: auto !important;
  display: inline-block;
  margin: 0 0 11px;
  vertical-align: middle;
  -webkit-transition: margin-top 0.4s;
}

.ace_dark::-webkit-scrollbar-thumb {
  background-color: rgba(255,255,255,0.6);
}

html {
  font-size: 16px !important;
}

body, #root {
  /*display: flex;
  flex-direction: column;
  flex: 1;*/
}

body {
  height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}

#root {
  min-height: 100vh;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

.link {
  color: var(--primary-color);
  text-decoration: underline;
  cursor: pointer;
  transition: all .3s;
}

.link:hover {
  color: var(--success-color);
}

div#hubspot-messages-iframe-container {
  pointer-events: none;
  z-index:1;
}

div#hubspot-messages-iframe-container iframe {
  bottom: 90px !important;
  right: 14px !important;
  pointer-events: all;
}

.app {
  padding-left: var(--main-columns-gap);
  padding-right: var(--main-columns-gap);
  padding-top: 78px;
  background-color: rgba(0,0,0,0.05);
  max-height: 100vh;
  display: flex;
  flex: 1;
  overflow: hidden;
  min-height: 100vh;
}

.app-columns {
  display: flex;
  flex: 1;
  font-family: var(--font-family);
  overflow: hidden;
}

.console-context {
    font-family: var(--font-family);
}

.m-tree {
  padding: 6px 6px 1rem 1rem;
  overflow-x: hidden;
  overflow-y: scroll !important;
  flex: 1;
}

.component-node {
  padding: 0.2rem 0.5rem 0.2rem 0.8rem;
  font-family: 'Montserrat';
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px;
  border-radius: 2px;
  font-size: 12px;
  margin-bottom: 1px;
  min-height: 2rem;
  background-color: white;
  display: flex;
  align-items: center;
  transition: background-color .3s;
  flex: 1;
  justify-content: space-between;
}

.component-node:hover {
  color: var(--primary-color);
}

.component-node.disabled {
  opacity: 0.5;
  color: gray;
}

.component-node.selected {
  background-color: var(--primary-color);
  color: white;
}

.component-node__icon {
  margin-right: 0.6rem;
  opacity: 0.3;
  transition: all .3s;
}

.component-node:hover .component-node__icon {
  opacity: 1;
}

.m-node .inner {
  display: flex;
  align-items: center;
}
.m-node .collapse {
  position: relative;
  transform: translateX(-0.4rem);
  transition: all .3s;
  transform-origin: center center;
}

.caret-down,
.caret-right {
  margin-right: 0.4rem;
}

.m-node .caret-down:before {
  content: '\25B8' !important;
}

.m-node .caret-down {
  transform: translateX(-0.4rem) rotate(90deg);
}



.resource-list-fab {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    z-index: 1;
}

.resource-list-fab > * + * {
  margin-left: 1rem;
}

.resource-list-fab.fixed {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 2000;
}

.list-item.selected {
  background: rgba(0,0,0,0.05);
}

.list-item.selected .list-item__title * {
  color: var(--primary-color);
}

.list-item__title .material-icons {
  float: left;
  font-size: 1.4rem;
  margin-right: 0.6rem;
}

.list-item__secondary-action {
  opacity: 0;
  pointer-events: none;
}

.list-item:hover .list-item__secondary-action {
  pointer-events: all;
  opacity: 1;
}

.list-item:hover .list-item-columns {
  opacity: 0;
}

.list-item__avatar {
  position: relative;
}

.list-item__avatar.active {
  background-color: var(--primary-color);
}

.list-item__avatar_wrapper {
    position: relative;
    min-height: 40px;
}

.avatar-media {
  transition: all .3s;
}

.avatar-media.inactive {
  opacity: 0.1;
  filter: grayscale(1);
}

.list-item-columns {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-right: 2rem;
  font-family: 'Montserrat';
  font-size: 12px;
}

.list-item-columns > * + * {
  margin-left: 1rem;
}

.avatar-progress {
  opacity: 0.75;
  position: absolute;
  top: -3px;
  left: -3px;
  z-index: 1
}

.list-filter {
  margin-right: var(--main-columns-gap);
  width: var(--list-filter-width);
  display: none;
}

.search-input {
  background-color: rgba(0,0,0,0.05);
  border-radius: 3px;
  padding-left: 1rem;
  transition: background-color .3s;
  min-height: 48px;
  flex: 1;
}

.search-input__icon {
  transition: all .3s;
  opacity: 0.5;
  margin-right: 0.4rem;
}

.search-input:hover {
  background-color: rgba(0,0,0,0.07);
}

.scroll-container {
  overflow: hidden auto;
  padding: 1rem;
  flex: 1;
}

.resource-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;
  padding: 3px;
}

.grid-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
}

.resource-grid.cols-3 {
  grid-template-columns: repeat(3, 1fr);
}

.resource-card {
  transition: background-color .3s;
  cursor: pointer;
    display: flex;
    flex-direction: column;
}

.resource-card:hover {
  background-color: rgba(0,0,0,0.03);
}

.resource-card__title {
  max-width: 180px;
}

.media-preview {
  background-color: rgba(0,0,0,0.05);
}

.media-preview .media-preview__actions {
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s;
}

.media-preview:hover .media-preview__actions {
  opacity: 1;
  pointer-events: all;
  background-color: rgba(0,0,0,0.5);
  color: white;
}

.lang-fallback, .empty-string {
    font-style: italic;
    opacity: 0.5;
    display: inline-flex;
    align-items: center;
}

.lang-fallback > * + * {
    margin-left: 0.4rem;
}

.color-field {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  font-size: 0.8rem;
}

.color-field__value {
  font-size: 0.7rem;
}

.color-field.light_color {
  color: inherit;
}

.color-field--empty {
  color: black;
}

.color-scheme-field {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  font-family: var(--font-family);
}

.color-scheme-field > .color-field {
  cursor: pointer;
  transition: all .3s;
  height: 60px;
}

.color-scheme-field > .color-field:hover {
  box-shadow: 0 4px 10px rgba(0,0,0,0.3);
}

.select-option--not-found {
  opacity: 0.5;
  font-style: italic;
}

.theme-colors {
    display: flex;
    width: 100%;
}

.theme-colors--sm .theme-colors__color {
    width: 40px;
    height: 8px;
    flex: 1;
}

.link:hover {
    text-decoration: underline;
}

.link__icon {
    margin-left: 6px;
    opacity: 0.5;
}

.card-link {
    font-size: 1rem;
    display: inline-flex;
    align-items: center;
    margin-top: 0.4rem;
}

.drag-preview {
  background: white;
  border-radius: 4px;
  color: rgba(0,0,0,0.5);
  box-shadow: 0 5px 30px rgba(0,0,0,0.2);
  height: 62px;
  width: 400px;
  padding: 1rem;
  text-align: center;
  font-family: var(--font-family);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.attach-section-dialog {
  overflow: hidden;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 80vh;
  background-color: rgba(0,0,0,0.05);
  max-width: 1040px;
}

.sections-list {
  padding: 1rem;
  flex-shrink: 0;
  flex: 1;
}

.sections-list .resource-grid {
  width: 840px;
}

.sections-list .resource-list {
  box-shadow: none;
  background-color: transparent;
}

.sections-list .resource-grid {
  grid-template-columns: repeat(auto-fill, 380px);
  grid-template-rows: 280px;
  grid-gap: 1rem;
  padding: 1rem;
}

.section-attach-target-list {
  margin-right: 1rem;
  max-width: 380px;
}

.list-actions {
  background-color: rgba(0,0,0,0.05);
}

.list-actions > * + * {
  margin-left: 1rem;
}

.btn-group {
  display: flex;
}

.btn-group > button + button {
  margin-left: 1rem;
}

.side-filter-layout {
  display: flex;
}

.filter-layout--aside .list-filter-area {
  flex-basis: var(--filter-width);
  width: var(--filter-width);
  flex-shrink: 0;
  margin-right: 1rem;
}

.filter-layout--aside .list-filter-area .list-filters > * + * {
  margin-top: 1rem;
}

.filter-area-backdrop {
  opacity: 0;
  pointer-events: none;
}

.list-filters--horizontal {
  display: flex;
}

.list-filters .list-heading {
  padding-bottom: 0.4rem;
  opacity: 0.5;
  padding-left: 0;
  padding-right: 0;
}

.browser-dialog {
  padding: 1rem;
  background-color: rgba(0,0,0,0.05);
}

.lang-select, .device-select {
  background-color: white;
  color: var(--success-color);
  border-radius: 3px;
  text-transform: uppercase;
  width: 100%;
  cursor: pointer;
  font-weight: bold;
  font-size: 0.8rem;
  text-align: center;
  transition: all .3s;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  min-width: 48px;
}

.lang-select {
  padding: 8px 8px;
  margin: 0.6rem 0;
}

.lang-select:hover, .device-select:hover {
  background-color: var(--success-color);
  color: white;
}

.form--sm .form__field {
  display: flex;
}

.form--sm .form__label {
  width: 140px;
  font-size: 12px;
}

.form--sm > .form__field + .form__field {
  margin-top: 1rem;
}

.type-style-settings {
  width: 400px;
  padding: 2rem;
  font-family: var(--font-family);
}

.modules-sidebar {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
}

.field-mapping-outer {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.1);
    padding: 2rem;
    margin-bottom: 1rem;
}

.field-mapping {
    font-family: var(--font-family);
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px rgba(0,0,0,0.2);
    border-radius: 999px;
    padding: 0.4rem 0.8rem;
    font-size: 1rem;
    min-width: 100px;
}

.field-mapping > * + * {
  margin-left: 0.4rem;
}

.field-mapping-outer > * + * {
  margin-left: 0.4rem;
}

.mapped-field {
  flex-grow: 1;
  display:none;
}



.map-preview {
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.1);
    padding: 2px;
    display: flex;
    position: relative;
    flex: 1;
    height: 100%;
    min-width: 120px;
    min-height: 120px;
    justify-content: center;
    align-items: center;
}

.map-preview > img {
}