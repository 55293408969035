.upload-area > div {
    width: 100%;
}

.drop-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255,255,255,0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 2.4rem;
    color: rgba(0,0,0,0.5);
    z-index: 1;
}

.drop-overlay > * + * {
    margin-top: 1rem;
}