.bg-red { background-color: #f44336 !important }
.bg-pink { background-color:  #e91e63 !important }
.bg-purple { background-color:  #9c27b0 !important }
.bg-deep-purple { background-color:  #673ab7 !important }
.bg-indigo { background-color:  #3f51b5 !important }
.bg-blue { background-color:  #2196f3 !important }
.bg-light-blue { background-color:  #03a9f4 !important }
.bg-cyan { background-color:  #00bcd4 !important }
.bg-teal { background-color:  #009688 !important }
.bg-green { background-color:  #4caf50 !important }
.bg-light-green { background-color:  #8bc34a !important }
.bg-lime { background-color:  #cddc39 !important }
.bg-yellow { background-color:  #ffeb3b !important }
.bg-amber { background-color:  #ffc107 !important }
.bg-orange { background-color:  #ff9800 !important }
.bg-deep-orange { background-color:  #ff5722 !important }
.bg-brown { background-color:  #795548 !important }
.bg-grey { background-color:  #9e9e9e !important }

.red { color: #f44336 !important }
.pink { color:  #e91e63 !important }
.purple { color:  #9c27b0 !important }
.deep-purple { color:  #673ab7 !important }
.indigo { color:  #3f51b5 !important }
.blue { color:  #2196f3 !important }
.light-blue { color:  #03a9f4 !important }
.cyan { color:  #00bcd4 !important }
.teal { color:  #009688 !important }
.green { color:  #4caf50 !important }
.light-green { color:  #8bc34a !important }
.lime { color:  #cddc39 !important }
.yellow { color:  #ffeb3b !important }
.amber { color:  #ffc107 !important }
.orange { color:  #ff9800 !important }
.deep-orange { color:  #ff5722 !important }
.brown { color:  #795548 !important }
.grey { color:  #9e9e9e !important }
