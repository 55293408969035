.conversation {
    display: flex;
    flex-direction: column;
}

.message-form {
    display: flex;
    flex: 1;
    margin-top: 1.6rem;
    align-items: flex-start;
}

.message-form__content {
    flex: 1;
    margin: 0 0 0 1rem;
    display: flex;
    align-items: flex-start;
    padding: 0 8px;
}

.message-form__content {
    border: solid 2px rgba(0,0,0,0.2);
    border-radius: 2px;
}

.message-form__upload {
    color: rgba(0,0,0,.4);
}

.message-form__message {
    flex: 1;
}

.message-form__content textarea {
    width: 100%;
    font-family: inherit;
    border: none;
    padding: 12px;
    height: 40px;
    background-color: transparent;
}

.message-form__submit {
    color: var(--primary-color);
}

.message {
    display: flex;
}

.message__content {
    margin-left: 1rem;
    flex: 1;
}

.message__header {
    display: flex;
    font-size: 12px;
}

.message__header > * + * {
    margin-left: 0.6rem;
}

.message__author {
    font-weight: 600;
}

.message + .message {
    margin-top: 1rem;
}

.message__time {
    opacity: 0.5;
}

.message__body {
    margin-top: 4px;
}

.message__text {
    max-width: 32em;
}

.message__text + .message-media {
    margin-top: 1rem;
}

.message-form .media-grid, .message .media-grid {
    padding: 0;
}

.message-form .field__label, .message .field__label {
    display: none !important;
}

.message-media {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
    padding-bottom: 1rem;
}

/*.message-form {
    position: fixed;
    bottom: 0;
    padding: 1rem;
    background-color: white;
    z-index: 99999;
    right: 0;
    left: 0;
    box-shadow: 0 -2px 8px rgba(0,0,0,0.1);
}*/

/*.messages {
    padding-bottom: 74px;
}*/

.conversation-summary {
    background-color: rgba(0,0,0,0.05);
    padding: 1rem;
    border-radius: 4px;
}

.conversation-dialog {
    position: relative;
    padding: 1rem;
}

.conversation-show {
    margin: 1rem -1rem -1rem -1rem;
    padding: 0.4rem 1rem;
    display: flex;
    justify-content: center;
    background-color: rgba(255,255,255,0.8);
    font-size: 12px;
}

@media only screen and (max-width: 800px) {
    .message-form {
        padding: 0;
        border: solid 1px rgba(0,0,0,0.1);
    }
    .conversation-dialog {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    .conversation-dialog .message-form {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        background-color: white;
        box-shadow: 0 -2px 8px rgba(0,0,0,0.1);
    }
    .conversation-dialog .conversation {
        padding-bottom: 60px;
    }
    .message-form__avatar {
        display: none;
    }
    .message-form__content {
        border: 0;
        margin: 0;
    }
    .message-media {
        grid-template-columns: repeat(auto-fill, minmax(50px, 1fr))
    }
    .media-preview {
        height: 50px !important;
    }
    .message-form__content textarea {
        font-size: 16px;
    }
}

@media only screen and (min-width: 801px) {
    .message-form {
        bottom: 74px;
    }
}