.list-summary {
    padding: 0 1rem 1rem 1rem;
    display: flex;
    font-size: 12px;
}

.list-summary > * + * {
    margin-left: 1rem;
}

@media only screen and (min-width: 801px) {
    .list-summary {
        padding: 1rem;
        font-size: 14px;
        background-color: rgba(0,0,0,0.02);
    }
}