.app-menu {
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    background-color: rgba(0,0,0,0.05);
    position: relative;
}

.apps-nav {
    display: flex;
    font-family: var(--font-family);
    flex: 1;
    z-index: 1;
}

.apps-nav-layout {
    display: flex;
}

.apps-nav-item {
    font-size: 1.4rem;
}

.projects-nav {
    height: 600px;
    max-height: 100%;
    overflow: hidden auto;
    padding: 1rem;
    min-width: 280px;
    display: flex;
    flex-direction: column;
}

.projects-nav.projects-grid {
    flex:1;
}

.resources-nav {
    flex: 1;
}

.app-menu .resources-nav {
    padding: 1rem;
}

.apps-nav-item + .apps-nav-item {
    margin-left: 1.4rem;
}

.app-nav-resources {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0.4rem;
}

.app-nav-resources--4 {
    grid-template-columns: repeat(4, 1fr);
}

.app-nav-resource {
    display: flex;
}

.app-nav-resource > a {
    display: flex;
    flex: 1;
    justify-content: center;
}