.post {
    position: relative;
}

.post .field__label {
    display: none !important;
}

.post p:first-child {
    margin-top: 0;
}

.posts-list .list-scroll {
    padding: 4px;
}

.posts-list .post + .post {
    margin-top: 0.4rem;
}

.posts-list .resource-list--primary {
    background-color: transparent;
}

.post-actions {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
}

.post-form-actions {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
}

.post-form-actions > * + * {
    margin-left: 0.4rem;
}

.post-content {
    font-size: 14px;
    margin-bottom: 1rem;
    max-width: 42em;
}

.post-content a {
    color: var(--primary-color);
    transition: all .3s;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
    hyphens: auto;
}

.post-content a:hover {
    color: var(--success-color);
    text-decoration: underline;
}

.post-content .ql-editor.ql-blank::before {
    left: 0;
    right: 0;
}

.link-card {
    display: flex;
    max-width: 680px;
    position: relative;
}

.link-image img {
    max-width: 200px;
    float: left;
}

.link-info {
    padding: 1rem;
}

.link-title {
    margin-bottom: 1rem;
    font-weight: 600;
}

.link-description {
    opacity: 0.6;
    font-size: 14px;
}

.link-actions {
    position: absolute;
    top: 0;
    right: 0;
}

.post .select--inline {
    min-height: 0;
    display: inline-flex;
}