
.list-area {
    display: flex;
    /*flex-shrink: 1;*/
    flex: 1;
    position: relative;
    overflow: hidden;
}

.list-wrapper {
    display: flex;
    flex-direction: column;
    font-family: var(--font-family);
    flex: 1;
    max-height: 100%;
}

.list-wrapper.full-width {
    --list-width-sm: 100%;
    --list-width-lg: 100%;
}

.app-columns .list-wrapper.list-wrapper--list.list-wrapper--primary {
    max-width: var(--list-width-sm);
}

.app-columns .list-wrapper.list-wrapper--list.filter-layout--aside {
    max-width: var(--list-width-filter-aside);
}

.list-area.primary {
    /*flex-shrink: 0;
    flex-grow: 0;
    flex-basis: var(--list-width-sm);*/
}

.list-wrapper.list-collection {
    max-width: none;
}

.app-columns.filters-aside .list-area.primary {
    /*flex-basis: calc(var(--list-width-lg) + var(--list-filter-width));*/
}

.list-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(0,0,0,0.5);
}

.list-wrapper > * + * {
    /*margin-top: 0.6rem;*/
}

.list-wrapper.filter-layout--aside {
    display: flex;
    flex-direction: row;
}

.list-wrapper.filter-layout--aside > * + * {
    margin-top: 0;
}

.list-wrapper.filter-layout--top .list-filter-area {
    margin-bottom: 1rem;
}

.resource-list {
    position: relative;
    display: flex;
    transition: width .3s;
    flex: 1;
}

.list-scroll {
    overflow: auto;
    flex: 1;
    /*display: flex;*/
    /*flex-direction: column;*/
}

.list-scroll > ul > :last-child {
    margin-bottom: 48px;
}

.collection .list-scroll > ul > :last-child {
    margin-bottom: 0;
}

.list-scroll.hide {
    display: none;
}

.list-area .resource-list {
    flex:1;
}

.list-area .list-filter-area {
}

.list-aside {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0.4rem;
    border-left: solid 1px rgba(0,0,0,0.05);
}

.list-empty-state {
    flex: 1;
    font-family: var(--font-family);
    padding: 2rem;
}

.resource-list {
    overflow: hidden auto;
}

.resource-list--primary {
    background: white;
    box-shadow: 0 0 20px rgba(0,0,0,0.05);
    border-radius: 5px;
}

@media only screen and (min-width: 801px) {
    /*.list-area.primary .resource-list--primary {
        width: var(--list-width-lg);
        flex-basis: var(--list-width-lg);
        max-width: var(--list-width-lg);
    }*/
}

.resource-list--secondary {
    background-color: rgba(0,0,0,0.025);
    border-radius: 5px;
}

.resource-list + .form {
    margin-left: var(--main-columns-gap);
}

.list-empty-state {
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
}

.list-with-header {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.list-header {
    padding: 0.4rem 0 0.1rem 0;
}

.dialog .list-header {
    padding: 1rem 1rem 0 1rem;
}

.list-view-options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    min-height: 40px;
}

.dialog--finder {
    display: flex;
}

@media only screen and (min-width: 801px) {
    .dialog--finder {
        height: 600px;
    }
    .dialog--finder .list-filter-area {
        margin-right: 0;
        padding: 1rem;
        background-color: rgba(0,0,0,0.05);
    }
}

@media only screen and (max-width: 800px) {
    .dialog--finder {
        max-height: none;
    }

    .list-view-options {
        padding: 0.6rem;
    }

    .app-columns.form-view .list-area,
    .app-columns.form-view .modules-sidebar {
        display: none;
    }

    .form {
        position: relative !important;
        transform: none !important;
    }

    .form__header--mobile {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }
    .list-scroll>ul>:last-child {
        margin-bottom: 72px;
    }
}