.popover-form {
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.popover-form > * + * {
    margin-top: 0.2rem;
}

.popover-list .resource-list {
    max-height: 400px;
    min-width: 300px;
    min-height: 80px;
}

.popover-menu {
    overflow: hidden auto;
    max-height: 280px;
    min-width: 280px;
}

.popover-list .list-filter-area {
    margin-bottom: 0 !important;
    padding: 4px;
}

.popover-content {
    height: 200px;
    overflow: hidden auto;
    min-width: 300px;
    padding: 1rem;
    font-family: var(--font-family);
}

.popover-title {
    background-color: rgba(0,0,0,0.05);
    padding: 0.8rem 1.2rem;
    font-size: 12px;
    text-transform: uppercase;
    font-family: var(--font-family);
}

.popover-filter {
    padding: 0.8rem;
    display: flex;
    flex-direction: column;
}
.popover-filter > * + * {
    margin-top: 0.8rem;
}