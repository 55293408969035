.empty-state {
    font-family: var(--font-family);
    text-align: center;
    text-shadow: -1px -1px 0px white;
    opacity: 0.2;
    pointer-events: none;
}

.empty-state__title {
    font-size: 1rem;
}

.empty-state__title + .empty-state__description {
    margin-top: 1rem;
}

.empty-state__description {
    max-width: 20em;
}

.empty-state__icon {
    font-size: 2rem;
}

.form-field--collection .empty-state__icon {
    font-size: 2rem;
}

.list-collection .empty-state__icon {
    display: none;
}

.form-field--collection .empty-state__title {
    font-size: 1rem;
}