.react-calendar-timeline {
    font-family: var(--font-family);
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even, .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    border-bottom: solid 1px rgba(0,0,0,0.1);
}

.react-calendar-timeline .rct-vertical-lines .rct-vl {
    border-left: dashed 1px rgba(0,0,0,0.1);
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6, .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
    /*background-color: rgba(0,0,0,0.1);*/
    background-color: transparent;
}

.react-calendar-timeline .rct-header .rct-label-group,
.react-calendar-timeline .rct-sidebar-header {
    background: var(--primary-color);
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
    font-size: 12px;
    padding: 0 1rem;
    text-transform: uppercase;
}