.file-preview {
    background-color: rgba(0,0,0,0.05);
}

.file {
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.1);
    transition: all .3s;
    cursor: pointer;
    position: relative;
}

.file:hover {
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.2);
}

.file-footer {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.file-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 11px;
    padding: 0.4rem 0.6rem;
}

.media-grid {
    display: grid;
    grid-template-columns: repeat(6, minmax(90px, 1fr));
    padding: 10px;
}

@media only screen and (max-width: 800px) {
    .media-grid {
        grid-template-columns: repeat(3, minmax(90px, 1fr));
    }
}